import axios from 'axios';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { useEffect, useMemo, useState } from 'react';

import BaseTabs from 'components/Base/BaseTabs';

import IconMessage from './img/message.png';
import MessageModal from 'components/Modals/MessageModal';
import JobCancelModal from 'components/Modals/JobCancelModal';


const MyBids = () => {
	const [tabs, setTabs] = useState([
		{ value: 'pending', name: 'Pending', count: 0 },
		{ value: 'working', name: 'Hired', count: 0 },
		{ value: 'canceled', name: 'Canceled', count: 0 },
		{ value: 'completed', name: 'Completed', count: 0 },
		{ value: 'dispute', name: 'Dispute', count: 0 }
	]);

	const [currentTab, setCurrentTab] = useState(tabs[0].value);
	const [jobs, setJobs] = useState([]);
	const [showMessageModal, setShowMessageModal] = useState(false);
	const [showCancelModal, setShowCancelModal] = useState(false);
	const [messageModalCustomer, setMessageModalCustomer] = useState(null);
	const [jobId, setJobId] = useState(null);

	const countJobs = (data) => {
		setTabs(prev => {
			return prev.map(item => {
				let pendingCount = data.filter(job => job.status === 'pending' || job.status === 'pending_acceptance').length;
				if (item.value === 'pending') {
					item.count = pendingCount;
				} else {
					item.count = data.filter(job => job.status === item.value).length;
				}
				return { ...item };
			});
		});
	}

	useEffect(() => {
		axios.get('/profile/bids').then(({ data }) => {
			setJobs(data);
			countJobs(data)
		});
	}, []);

	const cancelWeeklyJob = async (jobId) => {
		try {
			await axios.patch(`/jobs/${jobId}/repeat/disable`, { weekly_repeat: false });
			setJobs(prev => prev.map(job => {
				if (job._id === jobId) {
					return {
						...job,
						weekly_repeat: false
					};
				}
				return job;
			}));
		} catch (error) {
			console.error(error);
		}
	}

	const cancelJob = async () => {
		try {
			await axios.patch(`/jobs/${jobId}/cancel`);

			setJobs(prev => {
				const updatedJobs = prev.map(job => {
					if (job._id === jobId) {
						return {
							...job,
							status: 'canceled'
						};
					}
					return job;
				});
				countJobs(updatedJobs);
				return updatedJobs;
			});
		} catch (error) {
			console.error(error);
		}
	}

	const acceptJob = async (jobId) => {
		try {
			await axios.patch(`/jobs/${jobId}/accept`)
			setJobs(prev => {
				const updatedJobs = prev.map(job => {
					if (job._id === jobId) {
						return {
							...job,
							status: 'working',
							bid: {
								...job.bid,
								status: 'hired'
							}
						};
					}
					return job;
				});
				countJobs(updatedJobs);
				return updatedJobs;
			});
		} catch (error) {
			console.log(error)
		}
	}

	const tabJobs = useMemo(() => {
		if (currentTab === 'pending') {
			return jobs.filter((job) => job.status === 'pending' || job.status === 'pending_acceptance');
		}

		if (currentTab === 'working') {
			return jobs.filter(
				(job) => job.status === 'working'
			);
		}

		if (currentTab === 'canceled') {
			return jobs.filter((job) => job.status === 'canceled');
		}

		if (currentTab === 'completed') {
			return jobs.filter(
				(job) => job.status === 'completed'
			);
		}

		if (currentTab === "dispute") {
			return jobs.filter(
				(job) => job.status === "dipute"
			)
		}

	}, [jobs, currentTab]);

	return (
		<div className="page bids mybids">
			<h1>My Jobs</h1>

			<BaseTabs
				tabs={tabs}
				onTab={setCurrentTab}
				current={currentTab}
				sticky
			>
				{!tabJobs.length && (
					<div className="empty">
						<span>{currentTab}</span> list is empty
					</div>
				)}

				{tabJobs.map((job) => (
					<div className="bid" key={job._id}>
						<div className="content">
							<Link className="title" to={`/jobs/${job._id}`}>
								{job.title}
							</Link>
							{job.status === "dispute" && <div className="dispute">This job is in dispute</div>}
							<div className="info">
								<div className="item">
									Customer:{' '}
									<b>
										{job.creator.name} {job.creator.surname}
									</b>
								</div>
								<div className="item">
									Date:
									<b>
										{moment(job.date).format("DD MMM. YYYY")} {job.time.startTime}-{job.time.endTime}
									</b>
								</div>
								<div className="item">
									Requested Price:
									<b>
										{job.bid.price
											? `Suggested $${job.bid.price}`
											: `Fixed $${job.price}`}
									</b>
								</div>
							</div>

							<div className="more">
								{/* <div className="message">{job.bid.message}</div> */}

								{job.bid.status === 'hired' && (job.status === 'working' || job.status === 'pending' || job.status === 'pending_acceptance') && (
									<div className="buttons large">
										{job.status === 'pending' && (
											<button
												className="btn bordered message"
												onClick={() => {
													acceptJob(job._id)
													setJobId(job._id)
												}}
											>
												Accept job
											</button>
										)}
										<button
											className="btn bordered message"
											disabled={job.status === "dispute"}
											onClick={() => {
												setShowCancelModal(true);
												setJobId(job._id);
												setMessageModalCustomer(job.creator);
											}}
										>
											Cancel job
										</button>
										{job.weekly_repeat !== null &&
											<button className="btn bordered message" disabled={!job.weekly_repeat} onClick={() => cancelWeeklyJob(job._id)}>
												{job.weekly_repeat ? 'Cancel weekly repeat' : 'Weekly repeat canceled'}
											</button>
										}
										<button
											className="btn bordered message"
											onClick={() => {
												setShowMessageModal(true);
												setMessageModalCustomer(job.creator);
											}}
										>
											<img src={IconMessage} alt="Message" />
											<span>Message</span>
										</button>
									</div>
								)}
							</div>
						</div>
					</div>
				))}
			</BaseTabs >
			<MessageModal
				show={showMessageModal}
				onClose={() => setShowMessageModal(false)}
				user={messageModalCustomer}
				onSubmit={() => cancelJob()}
			/>

			<JobCancelModal
				show={showCancelModal}
				onClose={() => setShowCancelModal(false)}
				user={messageModalCustomer}
				onSubmit={() => cancelJob()}
			/>
		</div >
	);
};

export default MyBids;